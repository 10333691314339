<template>
    <div class="sms_user_list">
        <el-card>
            <nav class="input" style="width: auto">
                <el-input v-model="search.keyword" size="medium" placeholder="请输入名称" />
            </nav>
            <nav class="input" style="width: 150px">
                <el-button size="small" @click="reset">重置</el-button>
                <el-button type="primary" size="small" @click="$refs.table.reload()">{{ "搜索" }}</el-button>
            </nav>
            <nav class="input" style="width: auto">
                <el-button type="primary" size="small" @click="add">{{ "创建标签" }}</el-button>
            </nav>
            <nav class="input" style="width: auto">
                <el-button type="primary" size="small" @click="handleBtn('创建标签(差集)')">{{
                    "创建标签 (差集)"
                }}</el-button>
              <el-button type="primary" size="small" @click="handleBtn('创建标签(合集)')">{{
                  "创建标签 (合集)"
                }}</el-button>
            </nav>
        </el-card>
        <el-card style="margin-top: 10px">
            <z-table ref="table" v-model="list" @query="queryList" fullscreenLoading>
                <el-table :data="list" style="width: 100%" border stripe :header-cell-style="rowClass">
                    <el-table-column prop="id" label="ID"> </el-table-column>
                    <el-table-column prop="name" label="名称"> </el-table-column>
                    <el-table-column prop="userNum" label="收件人数"> </el-table-column>
                    <el-table-column prop="remark" label="备注">
                        <template #default="{row}">
                            <Remark url="/admin/Sms/editTagRemark" :row="row"></Remark>
                        </template>
                    </el-table-column>
                    <el-table-column prop="manager" label="创建人"> </el-table-column>
                    <el-table-column prop="createTime" label="创建时间"> </el-table-column>

                    <el-table-column label="操作">
                        <template slot-scope="{ row }">
                            <el-button
                                @click="management(row)"
                                style="color: sandybrown;"
                                type="text"
                                icon="el-icon-s-help"
                                size="mini"
                                >管理收件人</el-button
                            >

                            <el-button @click="editor(row)" type="text" icon="el-icon-edit" size="mini">编辑</el-button>
                            <el-button
                                @click="onDelete(row)"
                                type="text"
                                icon="el-icon-delete"
                                size="mini"
                                style="color: red;"
                                >删除</el-button
                            >
                        </template>
                    </el-table-column>
                </el-table>
            </z-table>
        </el-card>
        <pop ref="pop" :title="edit ? '修改收件人标签' : '创建收件人标签'" @toAudit="toAudit">
            <el-form :model="form" ref="form" :rules="rules" label-width="80px" :inline="false" size="normal">
                <el-form-item label="名称">
                    <el-input v-model="form.name"></el-input>
                </el-form-item>
            </el-form>
        </pop>
        <pop ref="popDifferenceSet" :title="popTitle" @toAudit="toAuditPopDifferenceSet" width="680px">
            <div style="margin-bottom: 20px;">
              {{ popTitle=="创建标签(差集)"?'生成的新标签为 基础标签 减去去差标签 所剩下的数据':'生成的新标签为 基础标签 加上 合集标签 的所有数据' }}
            </div>
            <el-form :model="form" ref="form" :rules="rules" label-width="80px" :inline="false" size="normal">
                <el-form-item label="名称">
                    <el-input v-model="form.name"></el-input>
                </el-form-item>
                <el-form-item label="基础标签">
                    <fuzzySelection type="13" width="100%" :value.sync="form.tagId"></fuzzySelection>
                </el-form-item>
                <el-form-item v-if="popTitle=='创建标签(差集)'" label="去差标签">
                    <fuzzySelection multiple type="13" width="100%" :value.sync="form.diffTagIds"></fuzzySelection>
                </el-form-item>
              <el-form-item v-if="popTitle=='创建标签(合集)'" label="合集标签">
                <fuzzySelection multiple type="13" width="100%" :value.sync="form.plusTagIds"></fuzzySelection>
              </el-form-item>
            </el-form>
        </pop>
    </div>
</template>

<script>
import list from "/src/mixin/list.js";
import fuzzySelection from "@/components/fuzzySelection.vue";
import handle from "ant-design-vue/lib/vc-slider/src/Handle";
export default {
  computed: {
    handle() {
      return handle
    }
  },
    mixins: [list],
    components: {
        fuzzySelection,
    },
    data() {
        return {
          popTitle:"创建标签（差集）",
            search: {
                keyword: "",
            },
            form: {
                name: "",
            },
            edit: false,
        };
    },
    methods: {
    handleBtn(title){
      this.popTitle=title
      this.$refs.popDifferenceSet.show = true
    },
        getlist() {
            this.$refs.table.refresh();
        },
        async toAuditPopDifferenceSet() {
            if(this.popTitle == '创建标签(差集)'){
              let { data: res } = await this.$http.post("/admin/Sms/diffTag", {
                ...this.form,
                diffTagIds: this.form.diffTagIds ? this.form.diffTagIds.toString() : "",
              });
              if (res.errorCode == 200) {
                this.$message.success(res.message);
                this.getlist();
                this.form = this.$options.data.call(this).form;
                this.$refs.popDifferenceSet.show = false;
              }
            }else {
              let { data: res } = await this.$http.post("/admin/Sms/plusTag", {
                ...this.form,
                plusTagIds: this.form.plusTagIds ? this.form.plusTagIds.toString() : "",
              });
              if (res.errorCode == 200){
                this.$message.success(res.message);
                this.getlist();
                this.form = this.$options.data.call(this).form;
                this.$refs.popDifferenceSet.show = false;
              }
            }
        },
        management(val) {
            this.$router.push({
                name: "sms_user_list_management",
                query: { id: val.id, name: val.name },
            });
        },
        async queryList(val) {
            let { data: res } = await this.$http.get("/admin/Sms/getTagList", {
                params: { ...val, ...this.search },
            });
            if (res.errorCode == 200) {
                // this.$message.success(res.message);
                this.$refs.table.complete(res.data);
            }
        },
        async onDelete(row) {
            await this.$confirm("此操作将永久删除该, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            });
            let { data: res } = await this.$http.post("/admin/Sms/delTag", row);
            if (res.errorCode == 200) {
                this.$message.success(res.message);
                this.getlist();
            }
        },
        add() {
            this.form = this.$options.data.call(this).form;
            this.$refs.pop.show = true;
            this.edit = false;
        },
        async editor(row) {
            this.edit = true;
            let { data: res } = await this.$http.get("/admin/Sms/getTagById", { params: { id: row.id } });
            if (res.errorCode == 200) {
                this.$refs.pop.show = true;
                this.form = res.data;
            }
        },
        async toAudit() {
            let { data: res } = this.edit
                ? await this.$http.post("/admin/Sms/editTag", this.form)
                : await this.$http.post("/admin/Sms/createTag", this.form);
            if (res.errorCode == 200) {
                this.$message.success(res.message);
                this.getlist();
                this.$refs.pop.show = false;
            }
        },
        Ftag(val) {
            if (val == 0) {
                return "待审核";
            }
            if (val == 1) {
                return "审核通过";
            }
            if (val == 2) {
                return "审核不通过";
            }
            return "";
        },
    },
    mounted() {},
};
</script>

<style lang="less" scoped>
/deep/.el-dialog__body{
    padding-top: 20px;
}
</style>
